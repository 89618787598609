/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*
- for
- while
*/
/*add mermaid flowchart here*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    pre: "pre",
    code: "code",
    h3: "h3",
    h4: "h4",
    p: "p",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, "\n", React.createElement(_components.h2, null, "Basic Concept of a Loop"), "\n", "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "for (initialExpression; condition; updateExpression) {\n  // for loop body: statement\n}\n")), "\n", React.createElement(_components.h2, null, "For Loops"), "\n", React.createElement(_components.h3, null, "for"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "for (let j = 0; j < 5; j++) {\n  console.log(j)\n}\n/**\n * 0\n * 1\n * 2\n * 3\n */\n")), "\n", React.createElement(_components.h4, null, "for loop syntax variations"), "\n", React.createElement(_components.p, null, "I was debating adding the following variations for two reasons, primarily because I haven't seen them used often. Tow, they fall into the bucket of short hand which I heavely shy away from in development."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "/**\n *\n */\nfor (let i = 0; i < 5; ) {\n  console.log(i)\n  i++\n}\n\n/**\n *\n */\nfor (let k = 0; ; ) {\n  if (k > 3) break\n  console.log(k)\n  k++\n}\n\n/**\n *\n */\nfor (let h = 0; ; h++) {\n  if (h > 3) break\n  console.log(h)\n}\n\n/**\n *\n */\nlet a = 0\nfor (; a < 5; a++) {\n  console.log(a)\n}\n\n/**\n *\n */\nlet b = 0\nfor (; ; b++) {\n  if (b > 3) break\n  console.log(b)\n}\n\n/**\n *\n */\nlet l = 0\nfor (; l < 3; ) {\n  console.log(l)\n  l++\n}\n\n/**\n *\n */\nlet t = 0\nfor (; ; t++) {\n  if (t > 3) break\n  console.log(t)\n}\n\n/**\n *\n */\nlet j = 0\nfor (;;) {\n  if (j > 3) break\n  console.log(j)\n  j++\n}\n")), "\n", React.createElement(_components.h3, null, "for..in"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "💡 Do not use for in over an Array if the index order is important.\nIt is better to use a for loop, a for of loop, or Array.forEach() when the order is important. - W3school"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const object = { a: 1, b: 2, c: 3 }\nfor (o in obj) {\n  console.log(o)\n  console.log(object[o])\n}\n/**\n * a,\n * 1,\n * b,\n * 2,\n * c,\n * 3\n **/\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const arr = [1, 2, 3, 4]\nfor (i in arr) {\n  console.log(i)\n}\n\n/**\n * 0,\n * 1,\n * 2,\n * 3,\n */\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "for (i in arr) {\n  console.log(arr[i])\n}\n\n/**\n * a,\n * b,\n * c,\n * d,\n */\n")), "\n", React.createElement(_components.h3, null, "for..of"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "for (a of arr) {\n  console.log(a)\n}\n\n/**\n * a,\n * b,\n * c,\n * d\n */\n")), "\n", React.createElement(_components.h3, null, "While"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "let a = 0\nwhile (a < 5) {\n  console.log(a)\n  a++\n}\n/**\n * 0,\n * 1,\n * 2,\n * 3,\n * 4\n */\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
